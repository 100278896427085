<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <div v-else class="flex flex-col gap-10">
      <div class="flex items-center divide-x divide-romanSilver mx-2">
        <back-button label="Back" @onClick="$router.back()" variant="secondary" />
        <div class="flex flex-row justify-start items-center gap-3 ">
          <h1 class="text-xl text-left font-extrabold mx-3">Compensation</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <template>
        <template v-if="hasModuleAccess">
          <template>
            <div class="flex flex-col justify-start items-start gap-5 px-2">
              <card class="w-full flex flex-col justify-start items-start gap-5 p-3">
                <div class="w-full pb-5 flex flex-col gap-5">
                  <h3 class="font-bold text-lg text-darkPurple capitalize">
                    PAYGRADE: {{ payGradeName || '-' }}
                  </h3>
                  <card class="px-5 pb-5">
                    <CardFooter
                      reloadcard
                      @reload="onGetQuery"
                      @sortType="onGetQuery({ sort: $event })"
                      @searchResult="onGetQuery({ search: $event })"
                    />
                  </card>
                </div>
                <Table
                  v-if="paygrades.length"
                  :headers="headers"
                  :items="paygrades"
                  :has-number="true"
                  class="w-full"
                  :loading="isFetching"
                  :pagination-list="pagination"
                  page-sync
                  @page="onGetQuery({ page: $event })"
                  @itemsPerPage="onGetQuery({ perPage: $event })"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.location">
                      <div v-if="(Array.isArray(item.data.location))">
                        <div v-for="item in item.data.location" :key="item.id" class="flex justify-start gap-2">
                          <span class="font-normal text-sm leading-5 text-darkPurple">
                            {{ item.name }}
                          </span>
                        </div>
                      </div>
                      <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                        {{ item.data.location }}
                      </span>
                    </div>
                    <div v-if="item.function">
                      <div v-if="(Array.isArray(item.data.function))">
                        <div v-for="item in item.data.function" :key="item.id" class="flex justify-start gap-2">
                          <span class="font-normal text-sm leading-5 text-darkPurple">
                            {{ item.name }}
                          </span>
                        </div>
                      </div>
                      <span v-else class="font-normal text-sm leading-5 text-darkPurple">
                        {{ item.data.function }}
                      </span>
                    </div>
                    <div v-if="item.employee" class="font-normal text-sm leading-5 tracking-widest">
                      <div class="flex flex-col justify-start items-start">
                        <span class="text-darkPurple" v-if="item.data.employee">
                          {{ item.data.employee.fname }} {{ item.data.employee.lname }}
                        </span>
                        <p class="text-romanSilver">
                          <span v-if="item.data.paygrade.designations">
                            {{ item.data.paygrade.designations.name }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div v-if="item.lineManager" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple" v-if="item.data.lineManager">
                        {{ item.data.lineManager.fname }} {{ item.data.lineManager.lname }}
                      </span>
                    </div>
                    <div v-if="item.level" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple" v-if="item.data.level">
                        {{ item.data.level.name }}
                      </span>
                    </div>
                    <div v-if="item.grossPay" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple" v-if="item.data.grossPay">
                        {{ convertToCurrency(item.data.grossPay) }}
                      </span>
                    </div>
                    <div v-if="item.compaRatio" class="font-normal text-sm leading-5 tracking-widest">
                      <template v-if="item.data.compaRatio">
                        <span :class="(Math.round(item.data.compaRatio) < 100) ? 'text-flame' : 'text-mediumSeaGreen'">
                          {{ item.data.compaRatio.toFixed(2) }}%
                        </span>
                      </template>
                    </div>
                    <div v-if="item.userId">
                      <Menu left @click.stop="">
                        <template v-slot:title>
                          <Icon icon-name="more_icon" size="xs" />
                        </template>
                        <template>
                          <div class="flex flex-col w-60 h-18 p-2 justify-start items-start">
                            <div @click="priviledgedActions(item.data, 'compensationSummary')" class="w-full">
                              <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="file_todo" class-name="text-mediumSeaGreen" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    Compensation Summary
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div @click="priviledgedActions(item.data, 'discretionaryAdjustment')" class="w-full">
                              <div class="hover:bg-ghostWhite w-full rounded-md">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                  Discretionary Adjustment
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="hover:bg-ghostWhite w-full rounded-md"
                              @click="priviledgedActions(item.data.userId, 'viewRevisionHistory')">
                              <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                <Icon icon-name="icon-eye" class-name="text-mediumSeaGreen" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">
                                  View Revision History
                                </span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </Menu>
                    </div>
                  </template>
                </Table>
                <template v-else>
                  <div class="w-full flex flex-col justify-center items-center gap-5 px-10 py-20">
                    <Icon size="" class-name="w-80 h-80" icon-name="payinsight_Illustration" />
                    <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                      A list of Employee Pay Insights for your organisaton will be shown here. Once added you can filter and perform necessary actions
                    </p>
                  </div>
                </template>
              </card>
            </div>
          </template>
        </template>
        <div v-else>
          <ErrorComponent />
        </div>
      </template>
      <template>
        <CompensationSummary
          ref="compensation_summary"
          @adjust-pay="$refs.discret_adjustment.toggle($event)"
        />
        <DiscretionaryAdjustment
          review-type="pay_insight"
          ref="discret_adjustment"
          @submit="onGetQuery"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import * as _ from "lodash";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
  import CardFooter from "@/components/CardFooter";
  import Icon from "@/components/Icon";
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    name: 'ViewEmployeePayInsight',
    components: {
      BackButton,
      Breadcrumb,
      Table,
      Card,
      CardFooter,
      Menu,
      Icon,
      ErrorComponent,
      CompensationSummary: () => import("../Modals/CompensationSummary"),
      DiscretionaryAdjustment: () => import("../Modals/DiscretionaryAdjustment")
    },
    data(){
      return {
        isLoading: true,
        hasModuleAccess: false,
        isFetching: true,
        paygradeId: '',
        paygrades: [],
        payGradeName: '',
        breadcrumbs: [
          { disabled: false, text: "Compensation", href: "compensation", id: "Compensation" },
          { disabled: false, text: "Pay Insight", href: "CompensationPayInsight", id: "CompensationPayInsight" },
          { disabled: false, text: "View Employees", href: "ViewEmployeePayInsight", id: "ViewEmployeePayInsight" }
        ],
        headers: [
          { title: "location", value: "location" },
          { title: "function", value: "function" },
          { title: "employee", value: "employee" },
          { title: "manager", value: "lineManager" },
          { title: "job level", value: "level" },
          { title: "gross pay", value: "grossPay" },
          { title: "compa-ratio", value: 'compaRatio' },
          { title: "", value: "userId", image: true },
        ],
        pagination: { page: 1, lastPage: 1, total: 1, from: 1, to: 1 },
      }
    },
    methods: {
      onGetQuery(queryParams){
        if(queryParams !== undefined){
          this.queryParams = {...this.queryParams, ...queryParams}
          this.getPayGradePayInsightById({
            paygradeId: this.paygradeId,
            queryParams: this.queryParams
          })
        } else {
          this.queryParams = {}
          this.getPayGradePayInsightById({
            paygradeId: this.paygradeId,
            queryParams: this.queryParams
          })
        }
      },
      async priviledgedActions(payload, setAction){
        try {
          if(setAction === 'compensationSummary'){
            await this.$handlePrivilege('payInsight', 'viewEmployee(compensationSummary)')
            this.$refs.compensation_summary.toggle(payload)
          } else if(setAction === 'discretionaryAdjustment'){
            await this.$handlePrivilege('payInsight', 'viewEmployee(discretionaryAdjustment)')
            this.$refs.discret_adjustment.toggle(payload)
          } else if(setAction === 'viewRevisionHistory'){
            await this.$handlePrivilege('payInsight', 'viewEmployee(viewRevisionRegister)')
            this.$router.push({ name: 'RevisionHistory', params: { id: payload} })
          }
        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      async getPayGradePayInsightById(payload){
        this.isFetching = true
        await this.$_getPayGradePayInsightById(payload).then(({ data }) => {
          if(!_.isEmpty(data.employeePaygrades)){
            const paygradeInfo = data.employeePaygrades
            this.payGradeName = paygradeInfo.paygrade.paygrade.payGradeId
            this.paygrades = paygradeInfo?.employeePaygrades?.map(item => ({
              ...item,
              ...item.employee,
              ...item.paygrade,
              ...item.userDetails,
              paygradeId: item.paygrade.paygrade.id
            })
            )
          }
          this.pagination = data.meta
          this.isFetching = false
        })
      },
      async bootstrapModule(){
        this.paygradeId = this.$route.params.id
        await this.getPayGradePayInsightById({
          paygradeId: this.paygradeId,
          queryParams: this.queryParams
        })
      }
    },
    async created(){
      this.isLoading = true
      try {
        await this.$handlePrivilege('payInsight', 'viewEmployee')
        this.hasModuleAccess = true
        this.bootstrapModule()
        this.isLoading = false
      } catch (error) {
        this.hasModuleAccess = false
        this.isLoading = false
      }
    },
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
</style>
